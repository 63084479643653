<template>
  <div
    class="cover-page"
    :style="`background-image: url('${ layouts.data.portfolio.pageCoverUrl }')`"
  >
    <v-row
      no-gutters
      class="cover-page--content animate__animated animate__backInDown"
    >
      <v-col
        cols="4"
        class="left-panel"
      >
        <v-sheet
          rounded="lg"
          elevation="4"
          class="portfolio-logo"
        >
          <v-img
            contain
            max-height="42px"
            min-height="42px"
            class="portfolio-logo--image"
            :src="layouts.data.portfolio.iconUrl"
          ></v-img>
        </v-sheet>
        <div class="value f-s-24 f-w-700 m-t-15">
          {{ layouts.data.portfolio.name }}
        </div>
        <div class="label f-s-14 m-t-15">
          {{ layouts.data.portfolio.description }}
        </div>
        <v-spacer></v-spacer>
        <div>
          <div class="label f-s-12">Need some help?</div>
          <div class="value">Call Us at
            <a class="text-white text-decoration-none f-w-700">{{ layouts.data.portfolio.tollfreePhone }}</a>
          </div>
        </div>
        <v-divider
          dark
          class="my-2"
        ></v-divider>
        <div class="label f-s-12">&copy; <b>{{ layouts.data.portfolioWebsite }}</b>, ALL RIGHTS RESERVED.</div>
      </v-col>
      <v-col
        cols="8"
        class="right-panel"
      >
        <router-view></router-view>
      </v-col>
    </v-row>
    <v-overlay
      absolute
      dark
      opacity="0.6"
      z-index="0"
    ></v-overlay>
  </div>
</template>

<script>
import { Tool } from '@/assets/js/util'

export default {
  name: 'web-account-layout',
  data () {
    return {
      assists: {
        tool: new Tool()
      },
      layouts: {
        data: {
          portfolio: {
            hotlinePhone: ''
          },
          portfolioWebsite: ''
        },
        settings: {}
      }
    }
  },
  mounted () {
    const portfolio = this.$store.getters.getPortfolioJson

    if (this.assists.tool.isNotEmpty(portfolio)) {
      this.layouts.data.portfolio = portfolio
      if (this.assists.tool.isNotEmpty(portfolio.domain)) {
        this.layouts.data.portfolioWebsite = portfolio.domain
          .toUpperCase()
          .substring(4)
      }
      // const background = document.getElementById('panel')
      // background.style.backgroundImage = `url(${portfolio.pageCoverUrl})`
    }
  },
  watch: {
    'store.getters.getPortfolioJson': {
      handler (val) {
        if (this.assists.tool.isNotEmpty(val)) {
          this.layouts.data.portfolio = val
          if (this.assists.tool.isNotEmpty(val.domain)) {
            this.layouts.data.portfolioWebsite = val.domain
              .toUpperCase()
              .substring(4)
          }
          // const background = document.getElementById('panel')
          // background.style.backgroundImage = `url(${val.pageCoverUrl})`
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="sass" scoped>
.cover-page
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  height: 100%
  background-size: cover
  background-repeat: no-repeat
  & .label
    color: white
    opacity: 0.6
  & .value
    color: white
    opacity: 1
  &--content
    position: relative
    width: 80%
    height: 80%
    max-width: 1128px
    max-height: 720px
    z-index: 1
    & .left-panel
      display: flex
      flex-direction: column
      background-color: var(--theme_primary)
      border-radius: 24px 0 0 24px
      padding: 48px 32px
      & .portfolio-logo
        position: relative
        height: 64px
        width: 64px
        &--image
          position: absolute
          top: 50%
          left: 50%
          -webkit-transform: translate(-50%, -50%)
          -moz-transform: translate(-50%, -50%)
    & .right-panel
      background-color: white
      border-radius: 0 24px 24px 0
      padding: 48px 32px
</style>
